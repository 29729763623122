/**
 * Site header
 */
.site-header {
  min-height: $spacing-unit * 1.865;

  // Positioning context for the mobile navigation icon
  position: relative;
}

.root-nav {
  display: flex;
  flex-direction: row;
  // align-items: center;
  justify-content: space-between;
  @include media-query($on-palm) {
   flex-direction: column;
  }
}

.site-title {
  line-height: $base-line-height * $base-font-size * 2.25;
  margin-bottom: 0;

  &,
  &:visited {
    color: $text-color;
  }
}

.site-nav {
  line-height: $base-line-height * $base-font-size * 2.25;

  .page-link {
    color: $text-color;
    line-height: $base-line-height;
    text-transform: lowercase;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2);
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    @include relative-font-size(2);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h1, h2, h3, h4 {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }

  video {
    max-width: 100%;
    padding: 1rem 0;
  }
}
